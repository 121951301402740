import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import { exhibitorActions } from "app/states/exhibitor";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import './style.scss';
import LobbyConcierge from 'app/assets/AKPK/lobby-concierge-popup.png';
import BoothPopup from 'app/assets/AKPK/exhibitor-akpk-book-store-popup.png';


const GenericPopupModal = ({ history }) => {

    const dispatch = useDispatch();
    const closeGenericModal = () => dispatch(exhibitorActions.closeGenericModal());

    const showModal = useSelector(({ exhibitor }) => exhibitor.isGenericModalOpen);
    const contentDetails = useSelector(({ exhibitor }) => exhibitor.contentDetails);

    const handleClose = () => {
        closeGenericModal();
    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="exhibitor-store-modal"
        >
            <div className="app-modal mobile-app-modal generic-modal">
                <button type="button" className="btn-dismiss" onClick={closeGenericModal}>
                    x
                </button>
                {contentDetails == 'video' && <iframe src="https://www.youtube.com/embed/uBfh8ckHXCk"></iframe>}
                {contentDetails == 'url' && <a onClick={() => window.open("https://www.akpk.org.my", "_blank")}>Open Link</a>}
                {contentDetails == 'concierge' && <img className="concierge-img" src={LobbyConcierge}></img>}
                {contentDetails == 'plainText' && <img className="concierge-img" src={BoothPopup}></img>}
            </div>
        </Modal>
    )
}

export default withRouter(GenericPopupModal);