import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { agentActions } from "app/states/agent";
import { useDispatch, useSelector } from "react-redux";
import IconClose from "app/assets/icon_close.svg";
import IconAdd from "app/assets/Agent/icon_add_blue.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import IconRemoveWhite from "app/assets/icon-close-white.png";
import "./style.scss";
import MediaItem from "app/views/components/MediaItem/MediaItem";
import AgentAPI from "app/apis/agent";
import { commonActions } from "app/states/common";
import ReactLoading from "react-loading";
import { cartesian } from "app/helper/common";
import { PRODUCT_VARIANT_COLORS } from "app/config/settings";

const MAX_PRODUCT_PHOTO_UPLOAD = 10;

const AddProductModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeAddProductModal = () => dispatch(agentActions.closeAddProductModal());
	const refreshStoreProducts = (isRefresh) => dispatch(agentActions.refreshStoreProducts(isRefresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showModal = useSelector(({ agent }) => agent.showAddProductModal);
	const roomDetails = useSelector(({ agent }) => agent.roomDetails);

	const [name, setName] = useState("");
	const [price, setPrice] = useState("");
	const [description, setDescription] = useState("");
	const [pictures, setPictures] = useState([]);
	const [loading, setLoading] = useState(false);

	const [variants, setVariants] = useState({
		sizes: [],
		colors: [],
		options: [],
	});
	const [variantInput, setVariantInput] = useState({
		sizes: "",
		colors: "",
		options: "",
	});

	const [variantMatrix, setVariantMatrix] = useState([]);

	const resetStates = () => {
		setName("");
		setPrice("");
		setDescription("");
		setPictures([]);
		setLoading(false);
		setVariants({
			sizes: [],
			colors: [],
			options: [],
		});
		setVariantInput({
			sizes: "",
			colors: "",
			options: "",
		});
		setVariantMatrix([]);
	};

	const handleClose = () => {
		resetStates();
		closeAddProductModal();
	};

	const handleAddProduct = () => {
		setLoading(true);
		const storeId = roomDetails.store_ids[0];

		let formData = new FormData();
		formData.append("name", name);
		formData.append("currency", "MYR");
		formData.append("price", price);
		formData.append("description", description);

		pictures.forEach((picture) => {
			formData.append("image", picture);
		});

		if (variantMatrix.length > 0) {
			let processedVariants = { ...variants };
			for (let variantKey in processedVariants) {
				if (processedVariants[variantKey].length === 0) {
					delete processedVariants[variantKey];
				}
			}

			let processedVariantMatrix = variantMatrix.map((matrix) => {
				return {
					size: matrix.size,
					color: matrix.color,
					option: matrix.option,
					sku_code: matrix.sku_code,
					price: matrix.price,
					quantity: matrix.quantity,
					weight: matrix.weight,
					width: matrix.width,
					height: matrix.height,
					length: matrix.length,
					fragile: matrix.fragile,
					available: matrix.available,
				};
			});

			formData.append("variants", JSON.stringify(processedVariants));
			formData.append("skus", JSON.stringify(processedVariantMatrix));
		}

		AgentAPI.postAddStoreProduct(storeId, formData)
			.then((response) => {
				setLoading(false);
				resetStates();
				refreshStoreProducts(true);
				closeAddProductModal();

				openAlertSnackbar("Product added", "success");
			})
			.catch((error) => {
				setLoading(false);
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleUpload = (event) => {
		setPictures([...pictures, event.target.files[0]]);
	};

	const handleRemove = (index) => {
		let picturesCopy = [...pictures];
		picturesCopy.splice(index, 1);
		setPictures(picturesCopy);
	};

	const updateVariantMatrix = (updatedVariants) => {
		// Update variant matrix
		let variantsArray = [];
		let variantKeys = Object.keys(updatedVariants);
		variantKeys.forEach((key) => {
			variantsArray.push(updatedVariants[key]);
		});

		// Filter out empty array
		variantsArray = variantsArray.filter((variantArray) => variantArray.length > 0);

		let matrixResult = [];
		let matrix = cartesian(variantsArray);
		matrix.forEach((matrixCombo) => {
			let size = '';
			let color = '';
			let option = '';

			matrixCombo.forEach((comboKey, index) => {
				if (updatedVariants.sizes.includes(comboKey)) {
					size = comboKey;
				} else if (updatedVariants.colors.includes(comboKey)) {
					color = comboKey;
				} else if (updatedVariants.options.includes(comboKey)) {
					option = comboKey;
				}
			});

			matrixResult.push({
				combination: matrixCombo,
				sku_code: "",
				price: "",
				quantity: "",
				weight: "",
				width: "",
				height: "",
				length: "",
				fragile: 0,
				available: 0,
				size: size,
				color: color,
				option: option,
			});
		});

		setVariantMatrix(matrixResult);
	};

	const handleRemoveVariant = (variantKey, variantValue) => {
		// Remove variant
		let updatedVariants = { ...variants };
		let index = updatedVariants[variantKey].findIndex((value) => value === variantValue);
		updatedVariants[variantKey].splice(index, 1);
		setVariants(updatedVariants);

		// Update variant matrix
		updateVariantMatrix(updatedVariants);
	};

	const handleVariantOnEnter = (variantKey, event) => {
		if (event.key === "Enter") {
			event.preventDefault();

			// Add variant
			let updatedVariants = { ...variants };
			updatedVariants[variantKey] = [...updatedVariants[variantKey], event.target.value];
			setVariants(updatedVariants);
			setVariantInput({
				...variantInput,
				[variantKey]: "",
			});

			// Update variant matrix
			updateVariantMatrix(updatedVariants);
		}
	};

	const handleVariantOnBlur = (variantKey, event) => {
		if (event.target.value.trim() === "") {
			return;
		}

		// Add variant
		let updatedVariants = { ...variants };
		updatedVariants[variantKey] = [...updatedVariants[variantKey], event.target.value];
		setVariants(updatedVariants);
		setVariantInput({
			...variantInput,
			[variantKey]: "",
		});

		// Update variant matrix
		updateVariantMatrix(updatedVariants);
	};

	const handleVariantInputOnChange = (event) => {
		setVariantInput({
			...variantInput,
			[event.target.name]: event.target.value,
		});
	};

	const handleDeleteAllVariantByKey = (variantKey) => {
		let updatedVariants = { ...variants };
		updatedVariants[variantKey] = [];
		setVariants(updatedVariants);

		// Update variant matrix
		updateVariantMatrix(updatedVariants);
	};

	const handleOnChangeVariantMatrixInput = (event, index) => {
		let variantMatrixCopy = [...variantMatrix];

		if (event.target.name === "fragile" || event.target.name === "available") {
			variantMatrixCopy[index][event.target.name] = !variantMatrixCopy[index][event.target.name] ? 1 : 0;
		} else {
			variantMatrixCopy[index][event.target.name] = event.target.value;
		}

		setVariantMatrix(variantMatrixCopy);
	};

	const VariantBadge = ({ backgroundColor, value, name, onRemove }) => {
		return (
			<span className="variant-badge" style={{ backgroundColor: backgroundColor }}>
				<div>{name}</div>
				<div onClick={onRemove} className="ml-1">
					<img src={IconRemoveWhite} alt="remove" className="icon-remove" />
				</div>
			</span>
		);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="agent-add-product-modal">
			<div className="app-modal agent-add-product-modal">
				<div className="modal-header flex justify-between mb-4">
					<h1 className="modal-title flex items-center">
						<img src={IconAdd} alt="Add Product" className="icon-header" /> Add Product{" "}
					</h1>
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</div>
				<div className="modal-content">
					<form className="modal-form-container mb-5">
						<div className="grid grid-cols-12 gap-5 mb-3">
							<div className="col-span-6">
								<div className="grid grid-cols-12">
									<div className="col-span-12">
										<label>Name</label>
									</div>
									<div className="col-span-12">
										<input
											type="text"
											placeholder="your product name - make it clear and popping"
											name="name"
											value={name}
											onChange={(event) => setName(event.target.value)}
											required
										/>
									</div>
								</div>
							</div>
							<div className="col-span-2">
								<div className="grid grid-cols-12">
									<div className="col-span-12">
										<label>Price</label>
									</div>
									<div className="col-span-12">
										<input
											type="number"
											placeholder="100"
											name="price"
											value={price}
											onChange={(event) => setPrice(event.target.value)}
											required
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-8">
								<div className="grid grid-cols-12">
									<div className="col-span-12">
										<label>Description</label>
									</div>
									<div className="col-span-12">
										<textarea
											placeholder="write a nice description of your product "
											name="description"
											value={description}
											onChange={(event) => setDescription(event.target.value)}
											required
											rows={3}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 mb-3">
							<div className="col-span-10">
								<label>Pictures</label>
							</div>
							<div className="col-span-10">
								<div className="grid grid-cols-10 gap-5">
									{pictures.map((picture, index) => {
										return (
											<div key={index} className="col-span-2">
												<MediaItem
													thumbnail={URL.createObjectURL(picture)}
													onUpload={handleUpload}
													onRemove={() => handleRemove(index)}
												/>
											</div>
										);
									})}

									{pictures.length < MAX_PRODUCT_PHOTO_UPLOAD && (
										<div className="col-span-2">
											<MediaItem onUpload={handleUpload} />
										</div>
									)}
								</div>
							</div>
						</div>

						<hr className="my-5" />

						<div className="grid grid-cols-12 mb-3 variant-container">
							<div className="col-span-10">
								<label>Variants</label>
							</div>
							<div className="col-span-10">
								<div className="grid grid-cols-12 mb-3 gap-3">
									<div className="col-span-2 variant-label">Size</div>
									<div className="col-span-7 variant-input-wrapper">
										{variants.sizes.map((size, index) => {
											return (
												<VariantBadge
													key={index}
													backgroundColor="#34A853"
													value={size}
													name={size}
													onRemove={() => handleRemoveVariant("sizes", size)}
												/>
											);
										})}

										<span className="variant-input-container">
											<input
												className="col-span-7 variant-input"
												name="sizes"
												value={variantInput.sizes}
												onChange={handleVariantInputOnChange}
												onBlur={(event) => handleVariantOnBlur("sizes", event)}
												onKeyPress={(event) => handleVariantOnEnter("sizes", event)}
											/>
										</span>
									</div>
									<div className="col-span-1 variant-delete">
										<img
											src={IconDeleteGrey}
											alt="delete"
											onClick={() => handleDeleteAllVariantByKey("sizes")}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-10">
								<div className="grid grid-cols-12 mb-3 gap-3">
									<div className="col-span-2 variant-label">Color</div>
									<div className="col-span-7 variant-input-wrapper">
										{variants.colors.map((variant, index) => {
											return (
												<VariantBadge
													key={index}
													backgroundColor="#C53929"
													value={variant}
													name={variant}
													onRemove={() => handleRemoveVariant("colors", variant)}
												/>
											);
										})}

										<span className="variant-input-container">
											<input
												className="col-span-7 variant-input"
												name="colors"
												value={variantInput.colors}
												onChange={handleVariantInputOnChange}
												onBlur={(event) => handleVariantOnBlur("colors", event)}
												onKeyPress={(event) => handleVariantOnEnter("colors", event)}
											/>
										</span>
									</div>
									<div className="col-span-1 variant-delete">
										<img
											src={IconDeleteGrey}
											alt="delete"
											onClick={() => handleDeleteAllVariantByKey("colors")}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-10">
								<div className="grid grid-cols-12 mb-3 gap-3">
									<div className="col-span-2 variant-label">Option</div>
									<div className="col-span-7 variant-input-wrapper">
										{variants.options.map((variant, index) => {
											return (
												<VariantBadge
													key={index}
													backgroundColor="#4285F4"
													value={variant}
													name={variant}
													onRemove={() => handleRemoveVariant("options", variant)}
												/>
											);
										})}

										<span className="variant-input-container">
											<input
												className="col-span-7 variant-input"
												name="options"
												value={variantInput.options}
												onChange={handleVariantInputOnChange}
												onBlur={(event) => handleVariantOnBlur("options", event)}
												onKeyPress={(event) => handleVariantOnEnter("options", event)}
											/>
										</span>
									</div>
									<div className="col-span-1 variant-delete">
										<img
											src={IconDeleteGrey}
											alt="delete"
											onClick={() => handleDeleteAllVariantByKey("options")}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="variant-matrix-container mb-3">
							<table>
								<thead>
									<tr>
										<th className="text-left" style={{ minWidth: "146px" }}>
											Variants Matrix
										</th>
										<th style={{ minWidth: "162px" }}>SKU Code</th>
										<th style={{ minWidth: "141px" }}>Price (RM)</th>
										<th style={{ minWidth: "93px" }}>Stock (Qty)</th>
										<th style={{ minWidth: "93px" }}>Weight (kg)</th>
										<th style={{ minWidth: "93px" }}>Width (cm)</th>
										<th style={{ minWidth: "93px" }}>Height (cm)</th>
										<th style={{ minWidth: "93px" }}>Length (cm)</th>
										<th>Fragile</th>
										<th>Availability</th>
									</tr>
								</thead>
								<tbody>
									{variantMatrix.map((variantMatrixRow, index) => {
										const {
											combination,
											sku_code,
											price,
											quantity,
											weight,
											width,
											height,
											length,
											fragile,
											available,
										} = variantMatrixRow;

										let displayCombination = "";
										combination.forEach((comboKey, index) => {
											let color = "#000000";
											if (variants.sizes.includes(comboKey)) {
												color = PRODUCT_VARIANT_COLORS["size"];
											} else if (variants.colors.includes(comboKey)) {
												color = PRODUCT_VARIANT_COLORS["color"];
											} else if (variants.options.includes(comboKey)) {
												color = PRODUCT_VARIANT_COLORS["option"];
											}

											displayCombination += `<span style="color: ${color}">${comboKey}</span>`;

											if (combination.length - 1 !== index) {
												displayCombination += `<span class="mx-1 font-normal">+</span>`;
											}
										});

										return (
											<tr key={index}>
												<td className="variant-matrix-label">
													<div dangerouslySetInnerHTML={{ __html: displayCombination }}></div>
												</td>
												<td>
													<input
														className="input-box"
														type="text"
														name="sku_code"
														value={sku_code}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="price"
														value={price}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="quantity"
														value={quantity}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="weight"
														value={weight}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="width"
														value={width}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="height"
														value={height}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="length"
														value={length}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td className="text-center">
													<input
														type="checkbox"
														className="app-custom-checkbox"
														name="fragile"
														checked={fragile === 1 ? true : false}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td className="text-center">
													<input
														type="checkbox"
														className="app-custom-checkbox"
														name="available"
														checked={available === 1 ? true : false}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</form>
				</div>

				<div className="action-bar">
					<div className="grid grid-cols-12 gap-3">
						<div className="col-span-8"></div>
						<div className="col-span-2 flex justify-center items-center">
							<button type="button" className="btn-cancel" onClick={closeAddProductModal}>
								Cancel
							</button>
						</div>
						<div className="col-span-2 flex justify-center items-center">
							<button type="button" className="btn-publish" onClick={handleAddProduct}>
								Publish Product
							</button>
						</div>
					</div>
				</div>

				{loading && (
					<div className="app-loading-overlay">
						<ReactLoading type={"spinningBubbles"} color="grey" />
						<p className="mt-3">Loading</p>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default withRouter(AddProductModal);
