/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import IconCalendar from "app/assets/LandingPage/icon_calendar.png";
import IconAdmission from "app/assets/LandingPage/icon_admission.png";
import IconInternet from "app/assets/LandingPage/icon_internet.png";
import IconLinkedin from "app/assets/LandingPage/icon_linkedin.png";
import IconInstagram from "app/assets/LandingPage/icon_instagram.png";
import IconFacebook from "app/assets/LandingPage/icon_facebook.png";
import IconShare from "app/assets/LandingPage/icon_share.png";
import IconOverview from "app/assets/LandingPage/icon_overview.png";
import IconWebsiteBlack from "app/assets/LandingPage/icon_website_black.png";
import IconLinkedinBlack from "app/assets/LandingPage/icon_linkedin_black.png";
import IconInstagramBlack from "app/assets/LandingPage/icon_instagram_black.png";
import IconFacebookBlack from "app/assets/LandingPage/icon_facebook_black.png";
import { zeroPad, calcTimeDelta } from "react-countdown";
import moment from "moment";
import { HashLink } from "react-router-hash-link";
import ReactPlayer from "react-player";
import ReactLoading from "react-loading";
import EventAPI from "app/apis/event";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LocalStorageService from "app/services/localStorageService";
import LoginRegisterModal from "app/views/containers/Authentication/LoginRegisterModal/LoginRegisterModal";
import { authenticationActions } from "app/states/authentication";
import { useMediaQuery } from "react-responsive";
import EventRegistrationModal from "app/views/containers/Authentication/EventRegistrationModal/EventRegistrationModal";
import ShippingAddressModal from "app/views/containers/Authentication/ShippingAddressModal/ShippingAddressModal";
import { google, outlook, yahoo, ics } from "calendar-link";
import { ACTIVITY_CODE, ADD_CALENDAR_OPTIONS } from "app/config/settings";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import IconProfile from "app/assets/icon_profile.png";
import IconExhibitor from "app/assets/icon_exhibitor.png";
import IconLogout from "app/assets/icon-logout.png";
import TokenService from "app/services/tokenService";
import AuthenticationAPI from "app/apis/authentication";
import ProfileModal from "app/views/containers/Modal/ProfileModal/ProfileModal";
import IconCheck from "app/assets/icon_check.png";
import { EVENT_STATUS } from "app/config/apiEnums";
import axios from "axios";
import MobileProfileModal from "app/views/containers/Modal/MobileProfileModal/MobileProfileModal";
import { profileActions } from "app/states/profile";
import ResetPasswordModal from "app/views/containers/Modal/ResetPasswordModal/ResetPasswordModal";
import useQueryParams from "app/views/hooks/useQueryParams";
import TicketSummaryModal from "app/views/containers/Authentication/TicketSummaryModal/TicketSummaryModal";
import TicketCheckoutStatusModal from "app/views/containers/Authentication/TicketCheckoutStatusModal/TicketCheckoutStatusModal";
import { eventActions } from "app/states/event";
import CircularProgress from "@material-ui/core/CircularProgress";
import { USER_EVENT_PAYMENT_STATUS } from "app/config/apiEnums";
import { CURRENCY_SIGN } from "app/config/settings";

const DEFAULT_BG_COLOR = "#3e51f1";

const TimerComponent = ({ label, value }) => {
	return (
		<div className="flex flex-col items-center">
			<div className="timer-value">{value}</div>
			<div className="timer-label">{label}</div>
		</div>
	);
};

const NavMenu = ({ activeIcon, inactiveIcon, title, active, onClick, linkTo }) => {
	const handleOnClick = () => {
		if (onClick && typeof onClick === "function") {
			onClick(linkTo);
		}
	};

	return (
		<HashLink
			to={linkTo}
			className={`nav-menu ${active ? "nav-menu-active" : ""} inline-flex items-center`}
			onClick={handleOnClick}>
			{activeIcon && inactiveIcon && (
				<img src={active ? activeIcon : inactiveIcon} alt="nav-icon" className="nav-icon" />
			)}
			<div>{title}</div>
		</HashLink>
	);
};

const TopWave = ({ bgThemeColor }) => (
	<svg
		xmlnsXlink="http://www.w3.org/1999/xlink"
		id="top_wave"
		style={{ transform: "rotate(0deg)", transition: "0.3s", marginBottom: "-1px" }}
		viewBox="0 0 1440 100"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<defs>
			<linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
				<stop stopColor="rgba(255, 61.989, 0, 1)" offset="0%" />
				<stop stopColor="rgba(255, 11, 11, 1)" offset="100%" />
			</linearGradient>
		</defs>
		<path
			style={{ transform: "translate(0, 0px)", opacity: 1 }}
			fill={bgThemeColor ? `${bgThemeColor}` : DEFAULT_BG_COLOR}
			d="M0,50L30,53.3C60,57,120,63,180,55C240,47,300,23,360,11.7C420,0,480,0,540,6.7C600,13,660,27,720,38.3C780,50,840,60,900,53.3C960,47,1020,23,1080,11.7C1140,0,1200,0,1260,11.7C1320,23,1380,47,1440,58.3C1500,70,1560,70,1620,63.3C1680,57,1740,43,1800,35C1860,27,1920,23,1980,21.7C2040,20,2100,20,2160,20C2220,20,2280,20,2340,21.7C2400,23,2460,27,2520,35C2580,43,2640,57,2700,58.3C2760,60,2820,50,2880,46.7C2940,43,3000,47,3060,40C3120,33,3180,17,3240,10C3300,3,3360,7,3420,11.7C3480,17,3540,23,3600,23.3C3660,23,3720,17,3780,13.3C3840,10,3900,10,3960,8.3C4020,7,4080,3,4140,16.7C4200,30,4260,60,4290,75L4320,90L4320,100L4290,100C4260,100,4200,100,4140,100C4080,100,4020,100,3960,100C3900,100,3840,100,3780,100C3720,100,3660,100,3600,100C3540,100,3480,100,3420,100C3360,100,3300,100,3240,100C3180,100,3120,100,3060,100C3000,100,2940,100,2880,100C2820,100,2760,100,2700,100C2640,100,2580,100,2520,100C2460,100,2400,100,2340,100C2280,100,2220,100,2160,100C2100,100,2040,100,1980,100C1920,100,1860,100,1800,100C1740,100,1680,100,1620,100C1560,100,1500,100,1440,100C1380,100,1320,100,1260,100C1200,100,1140,100,1080,100C1020,100,960,100,900,100C840,100,780,100,720,100C660,100,600,100,540,100C480,100,420,100,360,100C300,100,240,100,180,100C120,100,60,100,30,100L0,100Z"
		/>
	</svg>
);

const BottomWave = ({ bgThemeColor }) => (
	<svg
		xmlnsXlink="http://www.w3.org/1999/xlink"
		id="wave"
		style={{ transform: "rotate(180deg)", transition: "0.3s", marginTop: "-1px" }}
		viewBox="0 0 1440 100"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<defs>
			<linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
				<stop stopColor="rgba(255, 61.989, 0, 1)" offset="0%" />
				<stop stopColor="rgba(255, 11, 11, 1)" offset="100%" />
			</linearGradient>
		</defs>
		<path
			style={{ transform: "translate(0, 0px)", opacity: 1 }}
			fill={bgThemeColor ? `${bgThemeColor}` : DEFAULT_BG_COLOR}
			d="M0,50L30,53.3C60,57,120,63,180,55C240,47,300,23,360,11.7C420,0,480,0,540,6.7C600,13,660,27,720,38.3C780,50,840,60,900,53.3C960,47,1020,23,1080,11.7C1140,0,1200,0,1260,11.7C1320,23,1380,47,1440,58.3C1500,70,1560,70,1620,63.3C1680,57,1740,43,1800,35C1860,27,1920,23,1980,21.7C2040,20,2100,20,2160,20C2220,20,2280,20,2340,21.7C2400,23,2460,27,2520,35C2580,43,2640,57,2700,58.3C2760,60,2820,50,2880,46.7C2940,43,3000,47,3060,40C3120,33,3180,17,3240,10C3300,3,3360,7,3420,11.7C3480,17,3540,23,3600,23.3C3660,23,3720,17,3780,13.3C3840,10,3900,10,3960,8.3C4020,7,4080,3,4140,16.7C4200,30,4260,60,4290,75L4320,90L4320,100L4290,100C4260,100,4200,100,4140,100C4080,100,4020,100,3960,100C3900,100,3840,100,3780,100C3720,100,3660,100,3600,100C3540,100,3480,100,3420,100C3360,100,3300,100,3240,100C3180,100,3120,100,3060,100C3000,100,2940,100,2880,100C2820,100,2760,100,2700,100C2640,100,2580,100,2520,100C2460,100,2400,100,2340,100C2280,100,2220,100,2160,100C2100,100,2040,100,1980,100C1920,100,1860,100,1800,100C1740,100,1680,100,1620,100C1560,100,1500,100,1440,100C1380,100,1320,100,1260,100C1200,100,1140,100,1080,100C1020,100,960,100,900,100C840,100,780,100,720,100C660,100,600,100,540,100C480,100,420,100,360,100C300,100,240,100,180,100C120,100,60,100,30,100L0,100Z"
		/>
	</svg>
);

const LandingPage = ({ history }) => {
	const { eventCode } = useParams();
	const queryParams = useQueryParams();

	let landingPageUrl = new URL(window.location.href);
	landingPageUrl.hash = "";
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const openLoginRegisterModal = () => dispatch(authenticationActions.openLoginRegisterModal());
	const setLoginRegisterTabIndex = (tabIndex) => dispatch(authenticationActions.setLoginRegisterTabIndex(tabIndex));
	const openProfileModal = () => dispatch(commonActions.openProfileModal());
	const setUserProfile = (userProfile) => dispatch(authenticationActions.setUserProfile(userProfile));
	const setIsLoggedIn = (isLogin) => dispatch(authenticationActions.setIsLoggedIn(isLogin));
	const openMobileProfileModal = () => dispatch(profileActions.openMobileProfileModal());
	const openTicketSummaryModal = () => dispatch(authenticationActions.openTicketSummaryModal());
	const openEventRegistrationModal = () => dispatch(authenticationActions.openEventRegistrationModal());
	const openShippingAddressModal = () => dispatch(authenticationActions.openShippingAddressModal());
	const setIsEnteringEvent = (isEntering) => dispatch(commonActions.setIsEnteringEvent(isEntering));
	const setEventSettingsToRedux = (eventSettings) => dispatch(eventActions.setEventSettings(eventSettings));
	const setTicketPaymentStatus = (ticketPaymentStatus) =>
		dispatch(eventActions.setTicketPaymentStatus(ticketPaymentStatus));
	const openTicketCheckoutStatusModal = () => dispatch(authenticationActions.openTicketCheckoutStatusModal());
	const setIsFromRegisterButton = (isFromRegisterButton) =>
		dispatch(authenticationActions.setIsFromRegisterButton(isFromRegisterButton));

	const userProfile = useSelector(({ auth }) => auth.userProfile);
	const isLoggedin = useSelector(({ auth }) => auth.isLoggedin);
	const ticketPaymentStatus = useSelector(({ event }) => event.ticketPaymentStatus);
	const refreshUserEventStatusCount = useSelector(({ auth }) => auth.refreshUserEventStatusCount);

	const userEventRole = LocalStorageService.getUserEventRole();
	const [isExhibitorOrAgent, setIsExhibitorOrAgent] = useState(
		userEventRole &&
			userEventRole.roles &&
			(userEventRole.roles.includes("agent") || userEventRole.roles.includes("admin"))
			? true
			: false
	);

	const [eventStatus, setEventStatus] = useState(EVENT_STATUS[0]);
	const [timeDelta, setTimeDelta] = useState(null);
	const [activeNavMenu, setActiveNavMenu] = useState("#overview");
	const [stickyNav, setStickyNav] = useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const [eventSetting, setEventSetting] = useState(null);
	const [webConfig, setWebConfig] = useState(null);
	const [logoUrl, setLogoUrl] = useState(null);
	const [eventCalendar, setEventCalendar] = useState(null);
	const [showCalendarDropdown, setShowCalendarDropdown] = useState(false);
	const [showProfileDropdownMenu, setShowProfileDropdownMenu] = useState(false);
	const [hasJoinedEvent, setHasJoinedEvent] = useState(false);
	const [eventBgThemeColor, setEventBgThemeColor] = useState(null);
	const [isFreeEvent, setIsFreeEvent] = useState(true);

	const [agendaChannels, setAgendaChannels] = useState([]);
	const [selectedChannelIndex, setSelectedChannelIndex] = useState(null);
	const [selectedChannelDays, setSelectedChannelDays] = useState([]);
	const [selectedChannelDayIndex, setSelectedChannelDayIndex] = useState(null);
	const [selectedChannelSessions, setSelectedChannelSessions] = useState([]);

	const overviewRef = useRef();
	const speakerRef = useRef();
	const eventHighlightRef = useRef();
	const featuredPartnersRef = useRef();
	const agendaRef = useRef();
	const organizerRef = useRef();
	const scrollIntoViewOffset = 162;

	const logActivity = (code) => {
		EventAPI.postLogActivity(code)
			.then(response => {
				// success
			})
			.catch(error => {
				console.error(error);
			});
	}

	// Log activity for visiting landing page
	useEffect(() => {
		logActivity(ACTIVITY_CODE['visit_landing_page'])
	}, []);

	const changeThemeColor = (theme) => {
		if (!theme) return;

		const root = document.documentElement;

		if (theme.background_color) {
			root.style.setProperty("--bg-landing-page", `#${theme.background_color}`);
		}
		if (theme.font_color1) {
			root.style.setProperty("--font-color1", `#${theme.font_color1}`);
		}
		if (theme.font_color2) {
			root.style.setProperty("--font-color2", `#${theme.font_color2}`);
		}
		if (theme.font_color3) {
			root.style.setProperty("--font-color3", `#${theme.font_color3}`);
		}
	};

	const getEventSetting = () => {
		const previewToken = queryParams.get("preview_token");
		let requestHeaders = {};
		if (previewToken) {
			requestHeaders["preview-token"] = previewToken;
		}

		EventAPI.getEventSetting(eventCode, requestHeaders)
			.then((response) => {
				const { event, web_config } = response;
				const { theme, status } = event;

				if (theme && theme.logo_url) {
					setLogoUrl(theme.logo_url);
				}

				if (theme && theme.background_color) {
					setEventBgThemeColor(`#${theme.background_color}`);
				}

				setEventSettingsToRedux(event);
				setEventSetting(event);
				setWebConfig(web_config);
				setEventStatus(EVENT_STATUS[status]);

				LocalStorageService.setEventIdByEventCode(eventCode, event.id);
				LocalStorageService.setEventSetting(eventCode, JSON.stringify(event));
				LocalStorageService.setWebConfig(eventCode, JSON.stringify(web_config));
				changeThemeColor(theme);

				// Set event calendar object
				const eventCalendarObject = {
					title: event.name,
					description: event.description,
					start: event.start_at,
					end: event.end_at,
				};

				setEventCalendar(eventCalendarObject);

				// Set the first channel as selected
				if (event.agendas.length > 0) {
					setAgendaChannels(event.agendas.map((agenda) => agenda.name));
					setSelectedChannelIndex(0);
					setSelectedChannelDays(event.agendas[0].days);

					if (event.agendas[0].days.length > 0) {
						setSelectedChannelDayIndex(0);
						setSelectedChannelSessions(event.agendas[0].days[0].sessions);
					}
				}

				// Check if event is free admission
				if (event.entry_tickets && event.entry_tickets.length > 0) {
					if (parseInt(event.entry_tickets[0].price) > 0) {
						setIsFreeEvent(false);
					}
				}

				// Hide loading
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				// If no such event code, use default ebizstart
				// window.location.href = "https://boom.my/";
			});
	};

	// Handle payment gateway redirect url
	useEffect(() => {
		const status = queryParams.get("status");
		if (status) {
			// remove query string to indicate it is processed
			window.history.replaceState(null, "Landing page", `/events/${eventCode}`);

			// Set payment status and open status modal
			setTicketPaymentStatus(USER_EVENT_PAYMENT_STATUS[status]);
			openTicketCheckoutStatusModal();
		}
	}, []);

	// Get event setting
	useEffect(() => {
		getEventSetting();
	}, []);

	// Get user info by event id, to check whether user has joined the event
	useEffect(() => {
		if (isLoggedin && eventCode) {
			// Add access token to axios after logged in
			const accessToken = TokenService.getAccessToken();
			axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

			EventAPI.getUserInfoByEventId()
				.then((response) => {
					const { user } = response;

					setHasJoinedEvent(true);
					setIsExhibitorOrAgent(user.roles.includes("agent") || user.roles.includes("admin") ? true : false);

					// Cache user event role data
					const userEventRoleData = {
						roles: user.roles,
						agent: user.agent,
					};
					LocalStorageService.setUserEventRole(eventCode, userEventRoleData);
				})
				.catch((error) => {
					console.error(error);
					if (error.data.data && error.data.data.payment.status === 1) {
						// Set payment status and open status modal
						setTicketPaymentStatus(USER_EVENT_PAYMENT_STATUS[error.data.data.payment.status]);
						setHasJoinedEvent(true);
					} else {
						setHasJoinedEvent(false);
					}
				});
		}
	}, [isLoggedin, eventCode, refreshUserEventStatusCount]);

	// Handle countdown interval
	useEffect(() => {
		if (isLoading) return;

		// Set time delta
		const eventStartTime = moment(eventSetting.start_at);
		const eventTimeDelta = calcTimeDelta(eventStartTime);
		setTimeDelta(eventTimeDelta);

		if (eventTimeDelta.completed) {
			return;
		}

		const timerCountdown = setInterval(() => {
			const countdownTimeDelta = calcTimeDelta(eventStartTime);
			setTimeDelta(countdownTimeDelta);

			if (countdownTimeDelta.completed) {
				clearTimeout(timerCountdown);
			}
		}, 1000);

		return () => {
			clearTimeout(timerCountdown);
		};
	}, [isLoading]);

	// Handle event countdown finished
	useEffect(() => {
		if (timeDelta && timeDelta.completed) {
			getEventSetting();
		}
	}, [timeDelta]);

	const handlePageScroll = () => {
		if (
			organizerRef &&
			organizerRef.current &&
			window.pageYOffset >= organizerRef.current.offsetTop - scrollIntoViewOffset
		) {
			setActiveNavMenu("#organizer");
		} else if (
			agendaRef &&
			agendaRef.current &&
			window.pageYOffset >= agendaRef.current.offsetTop - scrollIntoViewOffset
		) {
			setActiveNavMenu("#agenda");
		} else if (
			featuredPartnersRef &&
			featuredPartnersRef.current &&
			window.pageYOffset >= featuredPartnersRef.current.offsetTop - scrollIntoViewOffset
		) {
			setActiveNavMenu("#featured-partners");
		} else if (
			eventHighlightRef &&
			eventHighlightRef.current &&
			window.pageYOffset >= eventHighlightRef.current.offsetTop - scrollIntoViewOffset
		) {
			setActiveNavMenu("#event-highlights");
		} else if (
			speakerRef &&
			speakerRef.current &&
			window.pageYOffset >= speakerRef.current.offsetTop - scrollIntoViewOffset
		) {
			setActiveNavMenu("#speakers");
		} else {
			setActiveNavMenu("#overview");
		}

		if (overviewRef && overviewRef.current && window.pageYOffset >= overviewRef.current.offsetTop) {
			setStickyNav(true);
		} else {
			setStickyNav(false);
		}
	};

	// Handle page scroll event
	useEffect(() => {
		if (isLoading && !eventSetting) return;

		setTimeout(() => {
			window.addEventListener("scroll", handlePageScroll);
		}, 1000);

		return () => {
			window.removeEventListener("scroll", () => handlePageScroll);
		};
	}, [isLoading]);

	const handleCopyLandingPageLink = () => {
		openAlertSnackbar("Link copied", "success");
	};

	const handleLoginButtonOnClick = () => {
		setIsFromRegisterButton(false);
		setLoginRegisterTabIndex(0);
		openLoginRegisterModal();
	};

	const handleRegisterButtonOnClick = () => {
		setIsFromRegisterButton(true);
		setLoginRegisterTabIndex(1);
		openLoginRegisterModal();
	};

	const handleAddCalendarButtonClick = () => {
		setShowCalendarDropdown(!showCalendarDropdown);
	};

	const handleDownloadCalendar = (key) => {
		let calendarLink;

		switch (key) {
			case "google":
				calendarLink = google(eventCalendar);
				window.open(calendarLink, "_blank");
				break;
			case "outlook":
				calendarLink = outlook(eventCalendar);
				break;
			case "yahoo":
				calendarLink = yahoo(eventCalendar);
				break;
			default:
				calendarLink = ics(eventCalendar);
		}

		window.open(calendarLink, "_blank");
	};

	const handleSelectChannel = (selectedIndex) => {
		setSelectedChannelIndex(selectedIndex);
		setSelectedChannelDays(eventSetting.agendas[selectedIndex].days);
		setSelectedChannelDayIndex(0);

		if (eventSetting.agendas[selectedIndex].days.length > 0) {
			setSelectedChannelSessions(eventSetting.agendas[selectedIndex].days[0].sessions);
		}
	};

	const handleSelectChannelDay = (selectedIndex) => {
		setSelectedChannelDayIndex(selectedIndex);
		setSelectedChannelSessions(eventSetting.agendas[selectedChannelIndex].days[selectedIndex].sessions);
	};

	const handleProfileMenuOnClick = () => {
		setShowProfileDropdownMenu(!showProfileDropdownMenu);
	};

	const handleViewMyProfile = () => {
		setShowProfileDropdownMenu(false);

		if (isTabletOrMobile) {
			openMobileProfileModal();
		} else {
			openProfileModal();
		}
	};

	const goToAgentContentPage = () => {
		history.push(`/events/${eventCode}/agent/contents`);
	};

	const handleLogout = () => {
		setShowProfileDropdownMenu(false);
		setHasJoinedEvent(false);
		setIsLoggedIn(false);
		setUserProfile(null);
		AuthenticationAPI.postLogout();
		TokenService.clearTokens();
		LocalStorageService.clearAll();
		window.location.reload();
	};

	const handleEnterEvent = () => {
		setIsEnteringEvent(true);
		history.push(`/events/${eventCode}/lobby`);
		// history.push(`/events/${eventCode}/channel`);
	};

	const handleButtonClickJoinEvent = () => {
		// Check if user has joined the event
		if (!hasJoinedEvent) {
			// If not join,
			// Check whether event is free
			// and if is exhibitor/agent, free for exhibitor/agent
			if (eventSetting && !isFreeEvent && !isExhibitorOrAgent) {
				// Paid event
				openTicketSummaryModal();
			} else {
				// Free event
				openEventRegistrationModal();
			}
		} else if (eventSetting && eventSetting.ecommerce_enabled === 1) {
			openShippingAddressModal();
		}
	};

	const handleEventPartnerOnClick = (content_url) => {
		if (content_url) {
			window.open(content_url, "_blank");
			return;
		}
	};

	const VerifyingPaymentStatus = () => {
		return (
			<div className="status-verifying-payment mb-3">
				<CircularProgress classes={{ colorPrimary: "circular-color" }} size={15} />{" "}
				<span className="pl-3">Verifying Payment..</span>
			</div>
		);
	};

	const renderEventButtonBasedOnEventStatus = () => {
		if (isLoggedin && hasJoinedEvent) {
			// Check event status first, then only check payment status (if any)
			switch (eventStatus) {
				case EVENT_STATUS[1]: // Ongoing
					// Check if user event payment status is still processing
					if (!isFreeEvent && ticketPaymentStatus && USER_EVENT_PAYMENT_STATUS[1]) {
						return <VerifyingPaymentStatus />;
					}

					return (
						<button type="button" className="btn-enter-event mb-3" onClick={handleEnterEvent}>
							Enter Event
						</button>
					);
				case EVENT_STATUS[2]: // Paused
				case EVENT_STATUS[3]: // Ended
				case EVENT_STATUS[4]: // Inactive
					// Check if user event payment status is still processing
					if (!isFreeEvent && ticketPaymentStatus && USER_EVENT_PAYMENT_STATUS[1]) {
						return <VerifyingPaymentStatus />;
					}

					return (
						<button type="button" className="btn-enter-event btn-enter-event-disabled mb-3" disabled>
							Enter Event
						</button>
					);
				case EVENT_STATUS[0]: // Not started
					// Check if user event payment status is still processing
					if (!isFreeEvent && ticketPaymentStatus && USER_EVENT_PAYMENT_STATUS[1]) {
						return <VerifyingPaymentStatus />;
					}

					return (
						<div className="btn-join-event mb-3" onClick={handleButtonClickJoinEvent}>
							<img src={IconCheck} alt="joined" className="icon-join-event" />
							<span>Joined Event</span>
						</div>
					);
				default:
			}
		} else if (isLoggedin && !hasJoinedEvent) {
			return (
				<div className="btn-join-event mb-3" onClick={handleButtonClickJoinEvent}>
					<img src={IconCheck} alt="joined" className="icon-join-event" />
					<span>Join Event</span>
				</div>
			);
		} else {
			return (
				<button
					type="button"
					className={`btn-register mb-3 ${eventStatus === EVENT_STATUS[4] ? "btn-register-disabled" : ""}`}
					onClick={handleRegisterButtonOnClick}
					disabled={eventStatus === EVENT_STATUS[4]}>
					Register Now
				</button>
			);
		}
	};

	const EventTicketWrapper = () => {
		return (
			<div className={`event-ticket-wrapper ${isExhibitorOrAgent ? "long-event-ticket-wrapper" : ""}`}>
				<section className="ticket-top-section">
					<div className="grid grid-cols-8 mb-3">
						<div className="col-span-1">
							<img src={IconCalendar} alt="calendar" className="icon-calendar" />
						</div>
						<div className="col-span-7 event-datetime">
							<div className="mb-2 lg:mb-0">
								{moment(eventSetting.start_at).format("D MMM")} -{" "}
								{moment(eventSetting.end_at).format("D MMM, YYYY")}
							</div>
							<div>
								{moment(eventSetting.start_at).format("h:mmA")} -{" "}
								{moment(eventSetting.end_at).format("h:mmA")} (UTC +8)
							</div>
						</div>
					</div>
					<div className="grid grid-cols-8">
						<div className="col-span-1 my-auto">
							<img src={IconAdmission} alt="admission" className="icon-admission" />
						</div>
						<div className="col-span-7">
							{!isFreeEvent ? (
								<span>
									{CURRENCY_SIGN[eventSetting.entry_tickets[0].currency]}{" "}
									{eventSetting.entry_tickets[0].price}
								</span>
							) : (
								<span>Free Admission</span>
							)}
						</div>
					</div>
				</section>

				<div className="section-divider">
					<span className="ellipse ellipse-1 top-ellipse-left"></span>
					<div className="divider-dashed" />
					<span className="ellipse ellipse-2 top-ellipse-right"></span>
				</div>

				<section
					className={`ticket-center-section ${isExhibitorOrAgent ? "ticket-center-section-exhibitor" : ""}`}>
					{/* Event status */}
					{eventStatus === EVENT_STATUS[0] && (
						<div className="event-status-container">
							<div className="text-start-in mb-1">EVENT STARTS IN</div>
							{timeDelta && (
								<div className="timer-container text-center mb-3 flex justify-center items-start px-5">
									<TimerComponent label="Days" value={zeroPad(timeDelta.days)} />

									<span className="timer-divider px-3">:</span>

									<TimerComponent label="Hours" value={zeroPad(timeDelta.hours)} />

									<span className="timer-divider px-3">:</span>

									<TimerComponent label="Minutes" value={zeroPad(timeDelta.minutes)} />

									<span className="timer-divider px-3">:</span>

									<TimerComponent label="Seconds" value={zeroPad(timeDelta.seconds)} />
								</div>
							)}
						</div>
					)}

					{eventStatus === EVENT_STATUS[1] && (
						<div className="event-status-container mb-3 lg:mb-2">
							<div className="text-event-status mb-1">EVENT STATUS</div>
							<div className="status-ongoing">ONGOING</div>
						</div>
					)}

					{eventStatus === EVENT_STATUS[2] && (
						<div className="event-status-container mb-3">
							<div className="text-event-status mb-1">EVENT STATUS</div>
							<div className="status-paused">PAUSED</div>
						</div>
					)}

					{eventStatus === EVENT_STATUS[3] && (
						<div className="event-status-container mb-3">
							<div className="text-event-status mb-1">EVENT STATUS</div>
							<div className="status-ended">ENDED</div>
						</div>
					)}

					{eventStatus === EVENT_STATUS[4] && (
						<div className="event-status-container mb-3">
							<div className="text-event-status mb-1">EVENT STATUS</div>
							<div className="status-unpublished">UNPUBLISHED</div>
						</div>
					)}

					{/* Event Buttons */}
					{renderEventButtonBasedOnEventStatus()}

					<div className={`add-to-calendar-wrapper ${isExhibitorOrAgent ? "mb-3" : ""}`}>
						<button type="button" className="btn-add-to-calendar" onClick={handleAddCalendarButtonClick}>
							<img src={IconCalendar} alt="calendar" className="icon-calendar mr-2" /> Add to Calendar
						</button>
						{showCalendarDropdown && (
							<div className="add-calendar-list-dropdown">
								{ADD_CALENDAR_OPTIONS.map((calendarOption) => {
									const { key, name } = calendarOption;

									return (
										<div
											key={`calendar-${key}`}
											className="add-calendar-option cursor-pointer"
											onClick={() => handleDownloadCalendar(key)}>
											{name}
										</div>
									);
								})}
							</div>
						)}
					</div>

					{/* For exhibitor user */}
					{!isTabletOrMobile && isExhibitorOrAgent && (
						<button type="button" className="btn-manage-exhibitor mb-3" onClick={goToAgentContentPage}>
							<img src={IconCalendar} alt="calendar" className="icon-exhibitor mr-1" /> Manage Exhibitor
							Room
						</button>
					)}

					{isTabletOrMobile && isExhibitorOrAgent && (
						<div className="exhibitor-notice">
							To manage your exhibitor room, access to the Agent Dashboard from a desktop
						</div>
					)}
				</section>

				{/* <div className="divider-2" /> */}

				<div className="section-divider">
					<span className="ellipse ellipse-1 bottom-ellipse-left"></span>
					<div className="divider-dashed" />
					<span className="ellipse ellipse-2 bottom-ellipse-right"></span>
				</div>

				<section className="ticket-bottom-section">
					<div className="flex items-center">
						{eventSetting.social.website_url && (
							<a href={eventSetting.social.website_url} target="_blank" rel="noreferrer">
								<img src={IconInternet} alt="internet" className="icon-link" />
							</a>
						)}
						{eventSetting.social.linkedin_url && (
							<a href={eventSetting.social.linkedin_url} target="_blank" rel="noreferrer">
								<img src={IconLinkedin} alt="linkedin" className="icon-link" />
							</a>
						)}
						{eventSetting.social.instagram_url && (
							<a href={eventSetting.social.instagram_url} target="_blank" rel="noreferrer">
								<img src={IconInstagram} alt="instagram" className="icon-link" />
							</a>
						)}
						{eventSetting.social.facebook_url && (
							<a href={eventSetting.social.facebook_url} target="_blank" rel="noreferrer">
								<img src={IconFacebook} alt="facebook" className="icon-link" />
							</a>
						)}
					</div>
					<div>
						<CopyToClipboard
							text={landingPageUrl}
							onCopy={handleCopyLandingPageLink}
							className="cursor-pointer">
							<img src={IconShare} alt="share" className="icon-link" />
						</CopyToClipboard>
					</div>
				</section>
			</div>
		);
	};

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-screen w-screen">
				<ReactLoading type={"spinningBubbles"} color="grey" />
			</div>
		);
	}

	return (
		<div id="landing-page" className="w-screen">
			<section id="section-first" className="lg:h-screen">
				<div className="flex justify-between items-center mb-3">
					<div>{logoUrl && <img src={logoUrl} alt="logo" className="img-logo" />}</div>
					{isLoggedin && userProfile ? (
						<div className="profile-menu-wrapper">
							<img
								src={userProfile.profile_image_url || IconDefaultProfile}
								alt="profile"
								className="btn-profile-menu"
								onClick={handleProfileMenuOnClick}
							/>
							{showProfileDropdownMenu && (
								<>
									<div
										className="dropdown-bg-overlay-invisible"
										onClick={() => setShowProfileDropdownMenu(false)}></div>
									<div className="profile-dropdown-menu">
										<div className="profile-menu-item" onClick={handleViewMyProfile}>
											<div className="item-icon">
												<img src={IconProfile} alt="profile" />
											</div>
											<div className="item-label">My Profile</div>
										</div>
										{!isTabletOrMobile && isExhibitorOrAgent && (
											<div className="profile-menu-item" onClick={goToAgentContentPage}>
												<div className="item-icon">
													<img
														src={IconExhibitor}
														alt="exhibitor"
														className="icon-exhibitor"
													/>
												</div>
												<div className="item-label">Manage Exhibitor Room</div>
											</div>
										)}
										<div className="profile-menu-item" onClick={handleLogout}>
											<div className="item-icon">
												<img src={IconLogout} alt="logout" />
											</div>
											<div className="item-label text-logout">Logout</div>
										</div>
									</div>
								</>
							)}
						</div>
					) : (
						<button type="button" className="btn-login" onClick={handleLoginButtonOnClick}>
							Login
						</button>
					)}
				</div>

				<div className="section-content-wrapper section-first-content">
					<div className="event-title lg:hidden">{eventSetting.name}</div>

					<div className="img-landing-wrapper mb-8">
						<img
							src={eventSetting.image_url || "https://dummyimage.com/600x400.png"}
							alt="landing"
							className="img-landing"
						/>

						{/* Client ask to hide until further notice. Bcoz it blocks the cover image in mobile view */}
						{/* <div className="mobile-event-date-wrapper lg:hidden">
							<div className="date">{moment(eventSetting.start_at).format("D")}</div>
							<div className="month-year">{moment(eventSetting.start_at).format("MMMM, YYYY")}</div>
						</div> */}
					</div>

					{/* Mobile View: Event ticket */}
					<section className="event-ticket-section lg:hidden ">
						<EventTicketWrapper />
					</section>
				</div>
			</section>

			<div className="event-card-wrapper hidden lg:block">
				<div className="event-date-wrapper">
					<div className="date">{moment(eventSetting.start_at).format("D")}</div>
					<div className="month-year">{moment(eventSetting.start_at).format("MMMM, YYYY")}</div>
				</div>

				<div className="title">{eventSetting.name}</div>

				{/* Desktop View: Event ticket */}
				<section className="event-ticket-section">
					<EventTicketWrapper />
				</section>
			</div>

			<section id="overview" ref={overviewRef}>
				<nav className={`landing-nav-menu ${stickyNav ? "sticky-nav-menu" : ""}`}>
					<NavMenu
						activeIcon={IconOverview}
						inactiveIcon={IconOverview}
						title="Overview"
						active={activeNavMenu === "#overview"}
						linkTo="#overview"
						onClick={(toLink) => setActiveNavMenu(toLink)}
					/>

					<NavMenu
						title="Speakers"
						active={activeNavMenu === "#speakers"}
						linkTo="#speakers"
						onClick={(toLink) => setActiveNavMenu(toLink)}
					/>

					<NavMenu
						title="Event Highlights"
						active={activeNavMenu === "#event-highlights"}
						linkTo="#event-highlights"
						onClick={(toLink) => setActiveNavMenu(toLink)}
					/>

					<NavMenu
						title="Featured Partners"
						active={activeNavMenu === "#featured-partners"}
						linkTo="#featured-partners"
						onClick={(toLink) => setActiveNavMenu(toLink)}
					/>

					{eventSetting.agendas.length > 0 && (
						<NavMenu
							title="Agenda"
							active={activeNavMenu === "#agenda"}
							linkTo="#agenda"
							onClick={(toLink) => setActiveNavMenu(toLink)}
						/>
					)}

					<NavMenu
						title="Organizer"
						active={activeNavMenu === "#organizer"}
						linkTo="#organizer"
						onClick={(toLink) => setActiveNavMenu(toLink)}
					/>
				</nav>
				<div className="section-content-wrapper pt-8 px-4 lg:p-12">
					<h1 className="mb-6">About This Event</h1>
					{eventSetting.video_url && (
						<div className="video-wrapper mb-6">
							<ReactPlayer
								className="overview-video-player"
								url={eventSetting.video_url}
								controls
								config={{
									facebook: {
										appId: process.env.REACT_APP_FB_APP_ID,
									},
								}}
							/>
						</div>
					)}
					<div className="text-content" dangerouslySetInnerHTML={{ __html: eventSetting.description }}></div>
				</div>
			</section>

			<section id="speakers" ref={speakerRef}>
				<TopWave bgThemeColor={eventBgThemeColor} />

				<div className="speakers-body">
					<div className="section-content-wrapper px-10 py-4 lg:py-0">
						<h1 className="mb-6 lg:mb-12">Speakers</h1>

						<div className="grid grid-cols-12 cols-span-12 gap-5">
							{eventSetting.speakers.map((speaker, index) => {
								const { name, company, job_title, image_url } = speaker;

								return (
									<div key={`speaker-${index}`} className="speaker-item col-span-6 lg:col-span-3">
										<div className="speaker-thumbnail-wrapper">
											<img src={image_url} alt={name} className="thumbnail" />
											<img
												src={
													require(`../../../assets/LandingPage/speaker-effect-layer-${
														(index % 4) + 1
													}-1A2232.png`).default
												}
												alt={`layer`}
												className="layer-effect-top"
											/>
										</div>

										<div className="name">{name}</div>
										<div className="occupation">{job_title}</div>
										<div className="occupation">{company}</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<BottomWave bgThemeColor={eventBgThemeColor} />
			</section>

			<section id="event-highlights" ref={eventHighlightRef}>
				<div className="section-content-wrapper">
					<h1 className="mb-6">Event Highlights</h1>

					<div className="px-4 lg:px-10">
						{eventSetting.event_highlights.map((highlight, index) => {
							const { title, sub_title, image_url, start_at, end_at, description, highlight_name } =
								highlight;

							let dateTime = "";
							const startDate = start_at ? moment(start_at).format("DD MMMM") : "";
							const endDate = end_at ? moment(end_at).format("DD MMMM") : "";
							const isSameDay = startDate === endDate;

							if (start_at && end_at && isSameDay) {
								dateTime = `${moment(start_at).format("DD MMM YYYY")}, ${moment(start_at).format(
									"hh:mmA"
								)} - ${moment(end_at).format("hh:mmA")}`;
							} else if (start_at && end_at) {
								dateTime = `${moment(start_at).format("DD MMM YYYY, hh:mmA")} to ${moment(
									end_at
								).format("DD MMM YYYY, hh:mmA")}`;
							} else if (start_at) {
								dateTime = `${moment(start_at).format("DD MMM YYYY, hh:mmA")}`;
							} else if (end_at) {
								dateTime = `${moment(end_at).format("DD MMM YYYY, hh:mmA")}`;
							}

							return (
								<div
									key={`highlight-${index}`}
									className="highlight-item flex flex-col lg:flex-row mb-8">
									<div className="img-wrapper">
										<img src={image_url} alt="highlight" />
									</div>
									<div>
										<div className="title">{title}</div>
										<div className="subtitle">{sub_title}</div>
										<div className="datetime">{dateTime}</div>
										<div className="description">{description}</div>
										<div className="speaker">{highlight_name}</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>

			<section id="featured-partners" ref={featuredPartnersRef}>
				<TopWave bgThemeColor={eventBgThemeColor} />

				<div className="featured-partners-body">
					<div className="section-content-wrapper px-4 lg:px-20 py-4 lg:py-0">
						<h1 className="mb-4 lg:mb-6">Featured Partners</h1>

						<section className="partner-section">
							<h6 className="section-title">Event Partners</h6>
							<div className="flex justify-center items-start flex-wrap">
								{Object.keys(eventSetting.featured_partners).length > 0 &&
									eventSetting.featured_partners.event_partners &&
									eventSetting.featured_partners.event_partners.map((partner, index) => {
										const { name, image_url, content_url } = partner;

										return (
											<div
												key={`event-partner-${index}`}
												className={`partner-item ${content_url ? "cursor-pointer" : ""}`}
												onClick={() => handleEventPartnerOnClick(content_url)}>
												<div className="partner-thumbnail-wrapper">
													<img src={image_url} alt={name} />
												</div>
												<div className="partner-name">{name}</div>
											</div>
										);
									})}
							</div>
						</section>

						<section className="partner-section">
							<h6 className="section-title">Sponsors</h6>
							<div className="flex justify-center items-start flex-wrap">
								{Object.keys(eventSetting.featured_partners).length > 0 &&
									eventSetting.featured_partners.sponsors &&
									eventSetting.featured_partners.sponsors.map((partner, index) => {
										const { name, image_url, content_url } = partner;

										return (
											<div
												key={`sponsor-${index}`}
												className={`partner-item ${content_url ? "cursor-pointer" : ""}`}
												onClick={() => handleEventPartnerOnClick(content_url)}>
												<div className="partner-thumbnail-wrapper">
													<img src={image_url} alt={name} />
												</div>
												<div className="partner-name">{name}</div>
											</div>
										);
									})}
							</div>
						</section>

						<section className="partner-section last-section">
							<h6 className="section-title">Featured Exhibitors</h6>
							<div className="flex justify-center items-start flex-wrap">
								{Object.keys(eventSetting.featured_partners).length > 0 &&
									eventSetting.featured_partners.exhibitors &&
									eventSetting.featured_partners.exhibitors.map((partner, index) => {
										const { name, image_url, content_url } = partner;

										return (
											<div
												key={`exhibitor-${index}`}
												className={`partner-item ${content_url ? "cursor-pointer" : ""}`}
												onClick={() => handleEventPartnerOnClick(content_url)}>
												<div className="partner-thumbnail-wrapper">
													<img src={image_url} alt={name} />
												</div>
												<div className="partner-name">{name}</div>
											</div>
										);
									})}
							</div>
						</section>
					</div>
				</div>

				<BottomWave bgThemeColor={eventBgThemeColor} />
			</section>

			{eventSetting.agendas.length > 0 && (
				<section id="agenda" ref={agendaRef}>
					<div className="section-content-wrapper px-4 lg:px-16">
						<h1 className="mb-6">Agenda</h1>

						<div className="mb-12 px-4 lg:px-0">
							<div className="grid grid-cols-12 gap-3 mb-8 lg:mb-0">
								<div className="col-span-12 lg:col-span-2 filter-label mb-3 lg:mb-0 pt-2 text-center lg:text-left">
									Channels:
								</div>
								<div className="col-span-12 lg:col-span-10 flex items-center flex-wrap">
									{agendaChannels.map((channelName, index) => {
										return (
											<div
												key={`channel-${index}`}
												className={`channel-value ${
													selectedChannelIndex === index ? "channel-value-active" : ""
												}`}
												onClick={() => handleSelectChannel(index)}>
												{channelName}
											</div>
										);
									})}
								</div>
							</div>

							<div className="hidden lg:block divider mb-4"></div>

							<div className="grid grid-cols-12 gap-3">
								<div className="col-span-12 lg:col-span-2 filter-label mb-3 lg:mb-0 text-center lg:text-left">
									Dates:
								</div>
								<div className="col-span-12 lg:col-span-10 flex justify-center lg:justify-start items-center flex-wrap">
									{selectedChannelDays.map((day, index) => {
										const { date } = day;
										let momentDate = moment(date, "YYYY-MM-DD");

										return (
											<div
												key={`channel-day-${index}`}
												className={`date-value ${
													selectedChannelDayIndex === index ? "date-value-active" : ""
												}`}
												onClick={() => handleSelectChannelDay(index)}>
												<div className="dayOfWeek">{momentDate.format("ddd")}</div>
												<div className="dayOfMonth">{momentDate.format("DD")}</div>
												<div className="month-year">{momentDate.format("MMM, YYYY")}</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="session-list-container">
							{selectedChannelSessions.map((session, index) => {
								const { title, image_url, start_at, end_at, description, speaker } = session;

								return (
									<div
										key={`session-${index}`}
										className="session-item flex flex-col lg:flex-row mb-8">
										<div className="img-wrapper">
											<img src={image_url} alt="highlight" />
										</div>
										<div className="text-left">
											<div className="datetime">
												{moment(start_at).format("h:mmA")}-{moment(end_at).format("h:mmA")}
											</div>
											<div className="title">{title}</div>
											{speaker && (
												<div className="speaker">
													<img
														src={speaker.image_url || IconDefaultProfile}
														alt="thumbnail"
														className="speaker-thumbnail"
													/>
													{speaker.name}
												</div>
											)}
											<div
												className="description"
												dangerouslySetInnerHTML={{ __html: description }}></div>
										</div>
									</div>
								);
							})}
						</div>

						<hr className="mt-20 agenda-border-bottom" />
					</div>
				</section>
			)}

			<section id="organizer" className="h-screen" ref={organizerRef}>
				<div className="section-content-wrapper px-4 lg:px-16">
					<h1 className="mb-8">Organizer</h1>

					<div className="organizer-wrapper flex flex-col lg:flex-row justify-center items-center mb-8">
						<img src={eventSetting.organizer.image_url} alt="thumbnail" className="logo hidden lg:block" />
						<div className="flex flex-col items-center lg:items-start">
							<div className="text-hosted-by mb-3 lg:mb-0">Hosted by</div>
							<img src={eventSetting.organizer.image_url} alt="thumbnail" className="logo lg:hidden" />
							<div className="organizer-name">{eventSetting.organizer.name}</div>
						</div>
					</div>

					<div
						className="organizer-description mb-8"
						dangerouslySetInnerHTML={{ __html: eventSetting.organizer.description }}></div>

					<div className="organizer-links flex justify-center items-center mb-8">
						{eventSetting.organizer.social.website && (
							<a href={eventSetting.organizer.social.website} target="_blank" rel="noreferrer">
								<img src={IconWebsiteBlack} alt="internet" className="icon-link" />
							</a>
						)}
						{eventSetting.organizer.social.linkedin_url && (
							<a href={eventSetting.organizer.social.linkedin_url} target="_blank" rel="noreferrer">
								<img src={IconLinkedinBlack} alt="linkedin" className="icon-link" />
							</a>
						)}
						{eventSetting.organizer.social.instagram_url && (
							<a href={eventSetting.organizer.social.instagram_url} target="_blank" rel="noreferrer">
								<img src={IconInstagramBlack} alt="instagram" className="icon-link" />
							</a>
						)}
						{eventSetting.organizer.social.facebook_url && (
							<a href={eventSetting.organizer.social.facebook_url} target="_blank" rel="noreferrer">
								<img src={IconFacebookBlack} alt="facebook" className="icon-link" />
							</a>
						)}
					</div>
				</div>
			</section>

			{/* Mobile view: Fixed bottom button */}
			{/* {!hasJoinedEvent && ( */}
				<div className="mobile-cta-section block lg:hidden">
					<TopWave bgThemeColor={eventBgThemeColor} />

					<div className="mobile-cta-body">
						{/* <button type="button" className="btn-register" onClick={handleRegisterButtonOnClick}>
							Register Now
						</button> */}
						{renderEventButtonBasedOnEventStatus()}
					</div>
				</div>
			{/* )} */}

			{/* {hasJoinedEvent && eventStatus === EVENT_STATUS[1] && (
				<div className="mobile-cta-section block lg:hidden">
					<TopWave bgThemeColor={eventBgThemeColor} />

					<div className="mobile-cta-body">
						<button type="button" className="btn-enter-event" onClick={handleEnterEvent}>
							Enter Event
						</button>
					</div>
				</div>
			)} */}

			<LoginRegisterModal />
			<EventRegistrationModal />
			<ShippingAddressModal />
			<ProfileModal />
			<MobileProfileModal />
			<ResetPasswordModal />
			<TicketSummaryModal />
			<TicketCheckoutStatusModal />
		</div>
	);
};

export default withRouter(LandingPage);
