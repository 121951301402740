import * as types from "./types";

export const setExhibitorRoomId = (roomId) => ({
	type: types.SET_EXHIBITOR_ROOM_ID,
	params: {
		roomId,
	},
});

export const setExhibitorStoreId = (storeId) => ({
	type: types.SET_EXHIBITOR_STORE_ID,
	params: {
		storeId,
	},
});

export const setExhibitorRoomDetails = (exhibitorRoomDetails) => ({
	type: types.SET_EXHIBITOR_ROOM_DETAILS,
	params: {
		exhibitorRoomDetails,
	},
});

export const openMobileExhibitorRoomsModal = () => ({
	type: types.OPEN_MOBILE_EXHIBITOR_ROOMS_MODAL,
});

export const closeMobileExhibitorRoomsModal = () => ({
	type: types.CLOSE_MOBILE_EXHIBITOR_ROOMS_MODAL,
});

export const setExpandExhibitorCategory = (category) => ({
	type: types.SET_EXPAND_EXHIBITOR_CATEGORY,
	params: {
		category,
	},
});

// Set the store products array to display in exhibitor store and product modal
export const setStoreProducts = (products) => ({
	type: types.SET_STORE_PRODUCTS,
	params: {
		products,
	},
});

export const setExhibitorChatId = (chatId) => ({
	type: types.SET_EXHIBITOR_CHAT_ID,
	params: {
		chatId,
	},
});

export const setExhibitorDialogId = (dialogId) => ({
	type: types.SET_EXHIBITOR_DIALOG_ID,
	params: {
		dialogId,
	},
});

export const openExhibitorDownloadFilesModal = () => ({
	type: types.OPEN_EXHIBITOR_DOWNLOAD_FILES_MODAL,
});

export const closeExhibitorDownloadFilesModal = () => ({
	type: types.CLOSE_EXHIBITOR_DOWNLOAD_FILES_MODAL,
});

export const setPrivateChatJoinedTimestamp = (timestamp) => ({
	type: types.SET_PRIVATE_CHAT_JOINED_TIMESTAMP,
	params: {
		timestamp,
	},
});

export const setPrivateChatVisitors = (visitors) => ({
	type: types.SET_PRIVATE_CHAT_VISITORS,
	params: {
		visitors,
	},
});

export const refreshExhibitorRoomDetails = (refresh) => ({
	type: types.REFRESH_EXHIBITOR_ROOM_DETAILS,
	params: {
		refresh,
	},
});

/**
 * Exhibitor store modal actions
 */
export const openExhibitorStoreModal = () => ({
	type: types.OPEN_EXHIBITOR_STORE_MODAL,
});

export const closeExhibitorStoreModal = () => ({
	type: types.CLOSE_EXHIBITOR_STORE_MODAL,
});

/**
 * Product detail modal actions
 */
export const openProductDetailModal = (productId) => ({
	type: types.OPEN_PRODUCT_DETAIL_MODAL,
	params: {
		productId,
	},
});

export const closeProductDetailModal = () => ({
	type: types.CLOSE_PRODUCT_DETAIL_MODAL,
});

/**
 * Product order summary modal
 */
export const openProductOrderSummaryModal = () => ({
	type: types.OPEN_PRODUCT_ORDER_SUMMARY_MODAL,
});

export const closeProductOrderSummaryModal = () => ({
	type: types.CLOSE_PRODUCT_ORDER_SUMMARY_MODAL,
});

/**
 * Product ordered modal
 */
export const openProductOrderedSuccessModal = () => ({
	type: types.OPEN_PRODUCT_ORDERED_SUCCESS_MODAL,
});

export const closeProductOrderedSuccessModal = () => ({
	type: types.CLOSE_PRODUCT_ORDERED_SUCCESS_MODAL,
});

/**
 * Product ordered processing modal
 */
export const openProductOrderedProcessingModal = () => ({
	type: types.OPEN_PRODUCT_ORDERED_PROCESSING_MODAL,
});

export const closeProductOrderedProcessingModal = () => ({
	type: types.CLOSE_PRODUCT_ORDERED_PROCESSING_MODAL,
});

/**
 * Product ordered failed modal
 */
export const openProductOrderedFailedModal = () => ({
	type: types.OPEN_PRODUCT_ORDERED_FAILED_MODAL,
});

export const closeProductOrderedFailedModal = () => ({
	type: types.CLOSE_PRODUCT_ORDERED_FAILED_MODAL,
});

/**
 * Has pending private chat invitation
 */
export const setHasPendingPrivateChatInvitation = (hasPending) => ({
	type: types.SET_HAS_PENDING_PRIVATE_CHAT_INVITATION,
	params: {
		hasPending,
	},
});

/**
 * Set exhibitor store settings
 */
export const setExhibitorStoreSettings = (storeSettings) => ({
	type: types.SET_EXHIBITOR_STORE_SETTINGS,
	params: {
		storeSettings,
	},
});

/**
 * Set switch exhibitor room
 */
export const setSwitchExhibitorRoom = (isSwitching) => ({
	type: types.SET_SWITCH_EXHIBITOR_ROOM,
	params: {
		isSwitching,
	},
});

export const openOverlayToggle = (isOpen) => ({
    type: types.OPEN_OVERLAY_TOGGLE,
	params: {
		isOpen,
	},
});

export const closeOverlayToggle = () => ({
    type: types.CLOSE_OVERLAY_TOGGLE,
});

export const openGenericModal = () => ({
	type: types.OPEN_GENERIC_MODAL,
});

export const closeGenericModal = () => ({
	type: types.CLOSE_GENERIC_MODAL,
});

export const setPopupContentType = (contentType) => ({
	type: types.SET_POPUP_CONTENT_TYPE,
	params: {
		contentType,
	},
});
