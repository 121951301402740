import React, { useEffect } from "react";

const RootPage = () => {
	// Redirect to official website
	useEffect(() => {
		window.location.href = "https://boom.my/";
	}, []);

	return <></>;
};

export default RootPage;
