import * as types from "./types";

const initialState = {
	exhibitorRoomId: null,
	exhibitorStoreId: null,
	exhibitorRoomDetails: null,
	showMobileExhibitorRoomsModal: false,
	expandExhibitorCategory: null,
	storeProducts: [],
	exhibitorChatId: null,
	exhibitorDialogId: null,
	showExhibitorDownloadFilesModal: false,
	privateChatJoinedTimestamp: null,
	privateChatVisitors: [],
	isRefreshExhibitorRoomDetails: false,
	showExhibitorStoreModal: false,
	showProductImageModal: false,
	showProductOrderSummaryModal: false,
	showProductOrderedSuccessModal: false,
	showProductOrderedProcessingModal: false,
	showProductOrderedFailedModal: false,
	hasPendingPrivateChatInvitation: false,
	viewingProductId: null,
	exhibitorStoreSettings: null,
	isSwitchingExhibitorRoom: false,
	openOverlay: false,
	closeOverlay: false,
	isGenericModalOpen: false,
	contentDetails: null,
};

const ExhibitorReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_EXHIBITOR_ROOM_ID:
			return {
				...state,
				exhibitorRoomId: action.params.roomId,
			};
		case types.SET_EXHIBITOR_STORE_ID:
			return {
				...state,
				exhibitorStoreId: action.params.storeId,
			};
		case types.SET_EXHIBITOR_ROOM_DETAILS:
			return {
				...state,
				exhibitorRoomDetails: action.params.exhibitorRoomDetails,
			};

		// Mobile exhibitor room modal actions
		case types.OPEN_MOBILE_EXHIBITOR_ROOMS_MODAL:
			return {
				...state,
				showMobileExhibitorRoomsModal: true,
			};
		case types.CLOSE_MOBILE_EXHIBITOR_ROOMS_MODAL:
			return {
				...state,
				showMobileExhibitorRoomsModal: false,
			};

		// Set the expand exhibitor category for mobile view
		case types.SET_EXPAND_EXHIBITOR_CATEGORY:
			return {
				...state,
				expandExhibitorCategory: action.params.category,
			};

		case types.SET_STORE_PRODUCTS:
			return {
				...state,
				storeProducts: action.params.products,
			};

		case types.SET_EXHIBITOR_CHAT_ID:
			return {
				...state,
				exhibitorChatId: action.params.chatId,
			};
		case types.SET_EXHIBITOR_DIALOG_ID:
			return {
				...state,
				exhibitorDialogId: action.params.dialogId,
			};

		// Exhibitor download files modal
		case types.OPEN_EXHIBITOR_DOWNLOAD_FILES_MODAL:
			return {
				...state,
				showExhibitorDownloadFilesModal: true,
			};
		case types.CLOSE_EXHIBITOR_DOWNLOAD_FILES_MODAL:
			return {
				...state,
				showExhibitorDownloadFilesModal: false,
			};

		// Private chat details
		case types.SET_PRIVATE_CHAT_JOINED_TIMESTAMP:
			return {
				...state,
				privateChatJoinedTimestamp: action.params.timestamp,
			};
		case types.SET_PRIVATE_CHAT_VISITORS:
			return {
				...state,
				privateChatVisitors: action.params.visitors,
			};

		case types.REFRESH_EXHIBITOR_ROOM_DETAILS:
			return {
				...state,
				isRefreshExhibitorRoomDetails: action.params.refresh,
			};

		// EXHIBITOR STORE MODAL
		case types.OPEN_EXHIBITOR_STORE_MODAL:
			return {
				...state,
				showExhibitorStoreModal: true,
			};
		case types.CLOSE_EXHIBITOR_STORE_MODAL:
			return {
				...state,
				showExhibitorStoreModal: false,
			};

		// EXHIBITOR STORE PRODUCT IMAGES MODAL
		case types.OPEN_PRODUCT_DETAIL_MODAL:
			return {
				...state,
				showProductImageModal: true,
				viewingProductId: action.params.productId,
			};
		case types.CLOSE_PRODUCT_DETAIL_MODAL:
			return {
				...state,
				showProductImageModal: false,
				viewingProductId: null,
			};

		// Exhibitor product order summary modal
		case types.OPEN_PRODUCT_ORDER_SUMMARY_MODAL:
			return {
				...state,
				showProductOrderSummaryModal: true,
			};
		case types.CLOSE_PRODUCT_ORDER_SUMMARY_MODAL:
			return {
				...state,
				showProductOrderSummaryModal: false,
			};

		// Exhibitor product ordered modal
		case types.OPEN_PRODUCT_ORDERED_SUCCESS_MODAL:
			return {
				...state,
				showProductOrderedSuccessModal: true,
			};
		case types.CLOSE_PRODUCT_ORDERED_SUCCESS_MODAL:
			return {
				...state,
				showProductOrderedSuccessModal: false,
			};

		// Exhibitor product ordered processing modal
		case types.OPEN_PRODUCT_ORDERED_PROCESSING_MODAL:
			return {
				...state,
				showProductOrderedProcessingModal: true,
			};
		case types.CLOSE_PRODUCT_ORDERED_PROCESSING_MODAL:
			return {
				...state,
				showProductOrderedProcessingModal: false,
			};

		// Exhibitor product ordered failed modal
		case types.OPEN_PRODUCT_ORDERED_FAILED_MODAL:
			return {
				...state,
				showProductOrderedFailedModal: true,
			};
		case types.CLOSE_PRODUCT_ORDERED_FAILED_MODAL:
			return {
				...state,
				showProductOrderedFailedModal: false,
			};

		// Has pending private chat invitation
		case types.SET_HAS_PENDING_PRIVATE_CHAT_INVITATION:
			return {
				...state,
				hasPendingPrivateChatInvitation: action.params.hasPending,
			};

		// Set exhibitor store settings
		case types.SET_EXHIBITOR_STORE_SETTINGS:
			return {
				...state,
				exhibitorStoreSettings: action.params.storeSettings,
			};

		// Set switch exhibitor room
		case types.SET_SWITCH_EXHIBITOR_ROOM:
			return {
				...state,
				isSwitchingExhibitorRoom: action.params.isSwitching,
			};

		case types.CLOSE_OVERLAY_TOGGLE:
			return {
				...state,
				closeOverlay: true,
			};

		case types.OPEN_OVERLAY_TOGGLE:
			return {
				...state,
				openOverlay: action.params.isOpen,
			};

		case types.OPEN_GENERIC_MODAL:
			return {
				...state,
				isGenericModalOpen: true,
			};

		case types.CLOSE_GENERIC_MODAL:
			return {
				...state,
				isGenericModalOpen: false,
			};

		case types.SET_POPUP_CONTENT_TYPE:
			return {
				...state,
				contentDetails: action.params.contentType,
			};

		default:
			return state;
	}
};

export default ExhibitorReducer;
