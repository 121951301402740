/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Advertisement from "app/views/components/Advertisement/Advertisement";
import ExhibitorRooms from "app/views/containers/Exhibitor/ExhibitorRooms/ExhibitorRooms";
import ExhibitorDetail from "app/views/containers/Exhibitor/ExhibitorDetail/ExhibitorDetail";
import MenuBar from "app/views/containers/MenuBar/MenuBar";
import { useSelector, useDispatch } from "react-redux";
import ExhibitorAPI from "app/apis/exhibitor";
import { exhibitorSocket } from "app/services/socketService";
import { useParams, withRouter } from "react-router-dom";
import { exhibitorActions } from "app/states/exhibitor";
import ExhibitorTabs from "app/views/containers/Exhibitor/ExhibitorTabs/ExhibitorTabs";
import JoinPrivateChatModal from "app/views/containers/Modal/JoinPrivateChatModal/JoinPrivateChatModal";
import ExitPrivateChatModal from "app/views/containers/Modal/ExitPrivateChatModal/ExitPrivateChatModal";
import PrivateChatPeopleModal from "app/views/containers/Modal/PrivateChatPeopleModal/PrivateChatPeopleModal";
import ExhibitorStoreModal from "app/views/containers/Modal/ExhibitorStoreModal/ExhibitorStoreModal";
import ProductDetailModal from "app/views/containers/Modal/ProductDetailModal/ProductDetailModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BrandLogoMobile from "app/views/components/BrandLogoMobile/BrandLogoMobile";
import "./style.scss";
import { commonActions } from "app/states/common";
import LocalStorageService from "app/services/localStorageService";
import sessionStorageService from "app/services/sessionStorageService";
import ProductOrderSummaryModal from "app/views/containers/Exhibitor/ProductOrderSummaryModal/ProductOrderSummaryModal";
import ProductOrderedSuccessModal from "app/views/containers/Exhibitor/ProductOrderedSuccessModal/ProductOrderedSuccessModal";
import moment from "moment";
import TokenService from "app/services/tokenService";
import ProductOrderedProcessingModal from "app/views/containers/Exhibitor/ProductOrderedProcessingModal/ProductOrderedProcessingModal";
import useQueryParams from "app/views/hooks/useQueryParams";
import { ORDER_PAYMENT_STATUS } from "app/config/apiEnums";
import ProductOrderedFailedModal from "app/views/containers/Exhibitor/ProductOrderedFailedModal/ProductOrderedFailedModal";
import FloatingRedBtn from "app/assets/AKPK/float-button-red.png";
import GenericPopupModal from "app/views/containers/Modal/GenericPopupModal/GenericPopupModal";
import AkpkBooth from "app/assets/AKPK/akpk-exhibitor-booth-raw.png";
import BackBtn from "app/assets/AKPK/arrow_back_left_reverse_icon.png";

const Exhibitor = ({ history }) => {
	const { eventCode, roomId } = useParams();
	const queryParams = useQueryParams();

	const userProfile = LocalStorageService.getUserProfile();

	const dispatch = useDispatch();
	const setExhibitorRoomId = (roomId) => dispatch(exhibitorActions.setExhibitorRoomId(roomId));
	const setExhibitorStoreId = (storeId) => dispatch(exhibitorActions.setExhibitorStoreId(storeId));
	const setExhibitorChatId = (chatId) => dispatch(exhibitorActions.setExhibitorChatId(chatId));
	const setExhibitorDialogId = (dialogId) => dispatch(exhibitorActions.setExhibitorDialogId(dialogId));
	const setExhibitorRoomDetails = (exhibitorRoomDetails) =>
		dispatch(exhibitorActions.setExhibitorRoomDetails(exhibitorRoomDetails));
	const openJoinPrivateChatModal = () => dispatch(commonActions.openJoinPrivateChatModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setIsPrivateChat = (isPrivateChat) => dispatch(commonActions.setIsPrivateChat(isPrivateChat));
	const setPrivateChatJoinedTimestamp = (timestamp) =>
		dispatch(exhibitorActions.setPrivateChatJoinedTimestamp(timestamp));
	const setPrivateChatVisitors = (visitors) => dispatch(exhibitorActions.setPrivateChatVisitors(visitors));
	const refreshExhibitorRoomDetails = (refresh) => dispatch(exhibitorActions.refreshExhibitorRoomDetails(refresh));
	const setHasPendingPrivateChatInvitation = (hasPending) =>
		dispatch(exhibitorActions.setHasPendingPrivateChatInvitation(hasPending));

	const openProductOrderedSuccessModal = () => dispatch(exhibitorActions.openProductOrderedSuccessModal());
	const openProductOrderedProcessingModal = () => dispatch(exhibitorActions.openProductOrderedProcessingModal());
	const openProductOrderedFailedModal = () => dispatch(exhibitorActions.openProductOrderedFailedModal());

	const exhibitorRoomId = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomId);
	const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);
	const isPrivateChat = useSelector(({ common }) => common.isPrivateChat);
	const privateChatVisitors = useSelector(({ exhibitor }) => exhibitor.privateChatVisitors);
	const isRefreshExhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.isRefreshExhibitorRoomDetails);

	// To check if store related modal is opened
	const showExhibitorStoreModal = useSelector(({ exhibitor }) => exhibitor.showExhibitorStoreModal);
	const showProductOrderSummaryModal = useSelector(({ exhibitor }) => exhibitor.showProductOrderSummaryModal);
	const showProductOrderedSuccessModal = useSelector(({ exhibitor }) => exhibitor.showProductOrderedSuccessModal);
	const showProfileModal = useSelector(({ common }) => common.showProfileModal);

	// For akpk demo use
	const closeOverlayToggle = (isOpen) => dispatch(exhibitorActions.openOverlayToggle(isOpen));
	const openGenericModal = () => dispatch(exhibitorActions.openGenericModal());
	const setPopupContentType = (contentType) => dispatch(exhibitorActions.setPopupContentType(contentType));
	const setSwitchExhibitorRoom = (isSwitching) => dispatch(exhibitorActions.setSwitchExhibitorRoom(isSwitching));
	const open3D = useSelector(({ exhibitor }) => exhibitor.openOverlay);

	const [socketActionCode, setSocketActionCode] = useState(null);
	const [socketData, setSocketData] = useState(null);
	const [hasPrivateChatInvitation, setHasPrivateChatInvitation] = useState(false);
	const [exhibitorNotFound, setExhibitorNotFound] = useState(false);

	// Check if got payment status query params
	useEffect(() => {
		const paymentStatus = queryParams.get("status");
		if (paymentStatus !== null) {
			switch (parseInt(paymentStatus)) {
				case ORDER_PAYMENT_STATUS.UNPAID:
				case ORDER_PAYMENT_STATUS.PROCESSING:
					openProductOrderedProcessingModal();
					break;
				case ORDER_PAYMENT_STATUS.PAID:
					openProductOrderedSuccessModal();
					break;
				case ORDER_PAYMENT_STATUS.FAILED:
					openProductOrderedFailedModal();
					break;
				default:
			}

			// remove query string to indicate it is processed
			window.history.replaceState(null, "Exhibitor", `/events/${eventCode}/exhibitor/room/${exhibitorRoomId}`);
		}
	}, [queryParams]);

	// Check if event started. If not started, redirect to landing page
	useEffect(() => {
		const eventSetting = LocalStorageService.getEventSetting();
		const eventStartAt = moment(eventSetting.start_at);
		if (moment().isBefore(moment(eventStartAt))) {
			history.replace(`/events/${eventCode}`);
			return;
		}
	}, []);

	useEffect(() => {
		// If route url has sessionId param, update exhibitorRoomId in redux,
		// because other APIs are all using the session id stored in redux
		if (roomId) {
			setExhibitorRoomId(roomId);
		}
	}, []);

	useEffect(() => {
		if (isRefreshExhibitorRoomDetails) {
			getExhibitorRoomDetail(roomId);
			refreshExhibitorRoomDetails(false);
		}
	}, [isRefreshExhibitorRoomDetails]);

	// Determine whether socket is ready
	useEffect(() => {
		if (exhibitorRoomId && exhibitorSocket.connected === false) {
			exhibitorSocket.auth.token = TokenService.getAccessToken();
			exhibitorSocket.connect();
		}

		exhibitorSocket.on("ready", () => {
			// console.log('exhibitorSocket', exhibitorSocket);

			// Emit join room socket event
			if (exhibitorRoomId) {
				exhibitorSocket.emit("join", { room_id: exhibitorRoomId });
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exhibitorRoomId]);

	// reset has private chat invitation flag on refresh
	useEffect(() => {
		const hasPcInvitation = sessionStorageService.getHasPcInvitation();

		if (hasPcInvitation) {
			// To allow private chat invitation again
			sessionStorageService.removeHasPcInvitation();
		}
	}, []);

	// Listen socket for chat invitation
	useEffect(() => {
		exhibitorSocket.on("room-update", (message) => {
			// do something with incoming message from backend
			// console.log("exhibitorSocket: ", message);

			const { action_code, data } = message;
			const hasPcInvitation = sessionStorageService.getHasPcInvitation();

			// New private chat invitation, and user haven't join any private chat yet,
			// Prompt the join private chat modal
			if (action_code === 400 && !isPrivateChat && !hasPcInvitation) {
				const { chat_id } = data;

				sessionStorageService.setHasPcInvitation(true);
				setExhibitorChatId(chat_id);
				setHasPrivateChatInvitation(true);
			}

			// New joiner in private chat
			if (action_code === 401) {
				setSocketActionCode(action_code);
				setSocketData(data);
			}

			// User leave in private chat
			if (action_code === 402) {
				setSocketActionCode(action_code);
				setSocketData(data);
			}

			// User kicked from private chat
			if (action_code === 403) {
				// To allow private chat invitation again
				sessionStorageService.removeHasPcInvitation();

				setSocketActionCode(action_code);
				setSocketData(data);
			}

			// Private chat closed/disbanded
			if (action_code === 404) {
				const { chat_id } = data;

				// To allow private chat invitation again
				sessionStorageService.removeHasPcInvitation();

				// Join back the public dialog id
				getExhibitorRoomDetail(roomId);
				setIsPrivateChat(false);
				openAlertSnackbar("Private chat has been closed", "warning");
			}
		});

		// return () => {
		// 	// turning of socket listner on unmount
		// 	exhibitorSocket.off("room-update");
		// };
	}, []);

	useEffect(() => {
		if (socketActionCode && socketData) {
			// New joiner in private chat
			if (socketActionCode === 401) {
				const { user } = socketData;
				setPrivateChatVisitors([...privateChatVisitors, user]);
			}

			// User leave in private chat
			if (socketActionCode === 402) {
				const { user } = socketData;
				const { user_id } = user;

				let privateChatVisitorsCopy = privateChatVisitors;
				let remainingVisitors = privateChatVisitorsCopy.filter((visitor) => {
					return visitor.user_id !== user_id;
				});

				setPrivateChatVisitors(remainingVisitors);
			}

			// User kicked from private chat
			if (socketActionCode === 403) {
				const { chat_id, dialog_id, total, user } = socketData;
				const { user_id } = user;

				// If the current user id is the userid being kicked
				if (userProfile.id === user_id) {
					// Join back the public dialog id
					getExhibitorRoomDetail(roomId);
					setIsPrivateChat(false);
					openAlertSnackbar("You've been remove from the private chat", "warning");
				}

				// Remove the user from private chat visitors
				let privateChatVisitorsCopy = privateChatVisitors;
				let remainingVisitors = privateChatVisitorsCopy.filter((visitor) => {
					return visitor.user_id !== user_id;
				});

				setPrivateChatVisitors(remainingVisitors);
			}

			setSocketActionCode(null);
			setSocketData(null);
		}
	}, [socketActionCode, socketData]);

	useEffect(() => {
		// If route url does not have session id, use the first session id get from API (get channel list API)
		if (exhibitorRoomId) {
			const paymentStatus = queryParams.get("payment_status");
			let exhibitorUrl = `/events/${eventCode}/exhibitor/room/${exhibitorRoomId}`;

			if (paymentStatus) {
				exhibitorUrl += `?payment_status=${paymentStatus}`;
			}

			history.push(exhibitorUrl);
			getExhibitorRoomDetail(exhibitorRoomId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exhibitorRoomId]);

	useEffect(() => {
		if (hasPrivateChatInvitation) {
			setHasPrivateChatInvitation(false);
			queueOrOpenPrivateChatInvitationModal();
		}
	}, [hasPrivateChatInvitation]);

	const queueOrOpenPrivateChatInvitationModal = () => {
		// If any store related modal is opened, queue the private chat invitation modal
		if (
			showExhibitorStoreModal ||
			showProductOrderSummaryModal ||
			showProductOrderedSuccessModal ||
			showProfileModal
		) {
			setHasPendingPrivateChatInvitation(true);
		} else {
			openJoinPrivateChatModal();
		}
	};

	const getExhibitorRoomDetail = (exhibitorRoomId) => {
		ExhibitorAPI.getExhibitorRoomDetail(exhibitorRoomId)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					// Set exhibitor detail
					const exhibitorRoomDetail = response.data;

					setExhibitorRoomDetails(exhibitorRoomDetail);

					// Set store id
					const { store_ids } = exhibitorRoomDetail;
					if (store_ids.length > 0) {
						setExhibitorStoreId(store_ids[0]);
					} else {
						setExhibitorStoreId(null);
					}

					// Set chat id and dialog id
					const {
						chats: { public: publicChat, private: privateChat },
					} = exhibitorRoomDetail;

					// If got private chat, join the private chat
					if (privateChat.length > 0) {
						const { chat_id, dialog_id, joined_at } = privateChat[0];

						setPrivateChatJoinedTimestamp(joined_at);
						setExhibitorChatId(chat_id);
						setExhibitorDialogId(dialog_id);
						setIsPrivateChat(true);
					}
					// If no private chat, join public chat
					else {
						const { chat_id, dialog_id } = publicChat;

						setExhibitorChatId(chat_id);
						setExhibitorDialogId(dialog_id);
						setIsPrivateChat(false);
					}

					// Emit join room socket event
					exhibitorSocket.emit("join", { room_id: exhibitorRoomId });

					setExhibitorNotFound(false);
				} else if (code === 404) {
					setExhibitorNotFound(true);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		console.log(open3D);
	}, []);

	const hideOverlay = (toggleType) => {
		if (toggleType == 'store') {
			// setSwitchExhibitorRoom(true);
			closeOverlayToggle(false);
		} else {
			closeOverlayToggle(false);
		}
	}

	const setModalContentType = (contentType) => {
		openGenericModal();
		setPopupContentType(contentType);
	}

	return (
		<div id="exhibitor-page" className="flex flex-col lg:flex-row w-screen min-h-screen lg:pt-1 lg:pr-1">
			<div className="hidden lg:block lg:mr-1">
				<MenuBar />
			</div>

			<div className="flex flex-col lg:flex-row w-full">
				<div className="col-span-12 block lg:hidden">
					<BrandLogoMobile />
				</div>

				<div className="listing-wrapper col-span-12 lg:col-span-3">
					<ExhibitorRooms />
				</div>

				{/* its here */}
				{open3D && <div className="large-overlay col-span-12 lg:col-span-9">
					<img src={AkpkBooth} alt="logo" className="large-banner" />
					<img src={BackBtn} className="back-view-btn" onClick={() => hideOverlay('chat')}></img>
					<img src={FloatingRedBtn} className="btn-float-red btn-1" onClick={() => setModalContentType('plainText')}></img>
					<img src={FloatingRedBtn} className="btn-float-red btn-2" onClick={() => window.open("https://www.akpk.org.my", "_blank")}></img>
					<img src={FloatingRedBtn} className="btn-float-red btn-3" onClick={() => window.open("https://www.akpk.org.my/about-us#about-intro", "_blank")}></img>
					<img src={FloatingRedBtn} className="btn-float-red btn-4" onClick={() => setModalContentType('video')}></img>
					<button type="button" className="btn-cta btn-chat" onClick={() => hideOverlay('chat')}>
						Chat
					</button>
					<button type="button" className="btn-cta btn-store" onClick={() => hideOverlay('store')}>
						Store
					</button>
				</div>}
				{!open3D && <div className="main-wrapper col-span-12 lg:col-span-6">
					<div className="mb-2 hidden lg:block">
						<Advertisement type="leaderboard_banner" />
					</div>
					<div>
						<div className="mb-1">
							<ExhibitorDetail
								exhibitorRoomDetail={exhibitorRoomDetails}
								isNotFound={exhibitorNotFound}
							/>
						</div>
					</div>
				</div>}
				{!open3D && <div className="misc-wrapper col-span-12 lg:col-span-3">
					<div className="mb-2">
						<Advertisement type="customized_leaderboard_banner" />
					</div>
					<div className="exhibitor-tabs-wrapper">
						<ExhibitorTabs />
					</div>
				</div>}
			</div>

			{/* Mobile View Menu Bar */}
			<div className="col-span-12 block lg:hidden">
				<MenuBar />
			</div>

			{/* Private Chat */}
			<JoinPrivateChatModal />
			<ExitPrivateChatModal />
			<PrivateChatPeopleModal />

			{/* Store */}
			<ExhibitorStoreModal />
			<ProductDetailModal />
			<ProductOrderSummaryModal />
			<ProductOrderedSuccessModal />
			<ProductOrderedProcessingModal />
			<ProductOrderedFailedModal />

			<GenericPopupModal />
		</div>
	);
};

export default withRouter(Exhibitor);
