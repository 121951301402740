/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MenuBar from "../../containers/MenuBar/MenuBar";
import { useSelector, useDispatch } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import BrandLogoMobile from "../../components/BrandLogoMobile/BrandLogoMobile";
import "./style.scss";
import GenericPopupModal from "app/views/containers/Modal/GenericPopupModal/GenericPopupModal";
import IconMobileProfileInactive from "app/assets/AKPK/lobby-edited.png";
import FloatingBlueBtn from "app/assets/AKPK/float-button-blue-new.png";
import { exhibitorActions } from "app/states/exhibitor";


const Lobby = ({ history }) => {
	const { eventCode, sessionId } = useParams();

	const dispatch = useDispatch();
	const openGenericModal = () => dispatch(exhibitorActions.openGenericModal());
	const setPopupContentType = (contentType) => dispatch(exhibitorActions.setPopupContentType(contentType));

	const goToRoute = (routeName) => {
		console.log(routeName);
		history.push(`/events/${eventCode}/${routeName}`);
	}

	const openConcierge = (routeType) => {
		console.log(routeType);
		openGenericModal();
		setPopupContentType(routeType);
	}

	return (
		<div className="lobby-page flex flex-col lg:flex-row w-screen min-h-screen lg:pt-1 lg:pr-1">
			<div className="hidden lg:block lg:mr-1">
				<MenuBar />
			</div>

			<div className="relative flex flex-col lg:flex-row w-full">
				<img className="akpk-lobby" src={IconMobileProfileInactive}></img>
				<iframe src="https://www.youtube.com/embed/7pdHrO-LjS4"></iframe>
				<img src={FloatingBlueBtn} className="btn-float-blue btn-1" onClick={() => goToRoute('channel')}></img>
				<img src={FloatingBlueBtn} className="btn-float-blue btn-2" onClick={() => goToRoute('exhibitor')}></img>
				<img src={FloatingBlueBtn} className="btn-float-blue btn-3" onClick={() => openConcierge('concierge')}></img>
				<img src={FloatingBlueBtn} className="btn-float-blue btn-4"></img>
			</div>

			{/* Mobile View Menu Bar */}
			<div className="col-span-12 block lg:hidden">
				<MenuBar />
			</div>

			<GenericPopupModal />
		</div>
	);
};

export default withRouter(Lobby);
